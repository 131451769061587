import { Box, Switch, switchClasses, Theme, Typography, Button } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Banks, Cards, Tags } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import EditCardModal from './EditCardModal';
import DeleteCardModal from './DeleteCardModal';
import { formatingCardNumber } from '../../utils/formationCardNum';

const CardItem = ({
  card,
  banks,
  tagList,
  handleDelete,
  handleUpdate,
  selected,
  setSelected,
  handleEditCard,
}: {
  card: Cards;
  banks: Banks[];
  tagList: Tags[];
  handleUpdate: ({
    id,
    balance,
    accessibility,
  }: {
    id: number;
    balance: number;
    accessibility: boolean;
  }) => void;
  handleDelete: (id: number) => void;
  selected: readonly number[];
  setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>;
  handleEditCard: (
    cards: {
      id: number;
      bank_id: number;
      tag: number | null;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      send_id: string;
      daily_transaction_limit: number;
      allow_same_amount: boolean;
    }[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [accessibility, setAccessibility] = useState(card.is_active);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    setAccessibility(card.is_active);
  }, [card]);

  const handleChangeCheckbox = async () => {
    handleUpdate({
      id: card.id,
      balance: card.balance,
      accessibility: !accessibility,
    });
    setAccessibility(!accessibility);
  };
  const handleDeleteCard = async () => handleDelete(card.id);

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>
          <label style={{ position: 'relative', display: 'inline-block' }}>
            <input
              type="checkbox"
              checked={selected.includes(card.id)}
              onChange={event => {
                setSelected(ids =>
                  event.target.checked
                    ? ids.concat(card.id)
                    : ids.filter(itemId => itemId !== card.id)
                );
              }}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1,
                opacity: 0,
              }}
            />
            <div
              style={{
                display: 'inline-block',
                width: '20px',
                height: '20px',
                backgroundColor: selected.includes(card.id) ? '#F1F1F1' : 'transparent',
                border: '1px solid #F1F1F1',
                borderRadius: '6px',
              }}
            >
              {selected.includes(card.id) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="13"
                  fill="none"
                  style={{
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                    zIndex: 2,
                  }}
                >
                  <path
                    stroke="#684FDD"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m1 9 3.2 2.4a1 1 0 0 0 1.4-.1L14 1"
                  />
                </svg>
              )}
            </div>
          </label>
        </td>
        <td>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box>
              <Typography
                level="body-sm"
                sx={{ color: '#F1F1F1', overflowWrap: 'anywhere', }}
              >
                {formatingCardNumber(card.pocket_address)}
              </Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                {card.label}
              </Typography>
            </Box>
          </Box>
        </td>
        <td>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="body-sm" sx={{ color: '#CED4DA',  }}>
              {t('card_table_turnoverDay', {
                daily: (card.daily_balance && card.daily_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || '',
              })}
            </Typography>

            <Typography level="body-sm" sx={{ color: '#CED4DA', }}>
              {t('card_table_turnoverMonth', {
                monthly: (card.month_balance && card.month_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || 0,
              })}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1', }}>
            {`${card.daily_limit} ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1', }}>
            {`${card.month_limit} ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {card.daily_transaction_done} / {card.daily_transaction_limit}
          </Typography>
        </td>
        <td style={{ color: '#F1F1F1', fontSize: '16px' }}>{card.bank.bank.title}</td>
        <td>
          <Typography
            level="body-sm"
            sx={{
              bgcolor: (card.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
              width: 'fit-content',
              p: '2px 10px',
              borderRadius: '8px',
              color: '#F1F1F1',
           
            }}
          >
            {(card.tag && `#${card.tag.title}`) || t('not_found')}
          </Typography>
        </td>
        <td>
          {!card.archivated && (
            <Switch
              size="md"
              variant={'solid'}
              checked={accessibility}
              onChange={handleChangeCheckbox}
              sx={(theme: Theme) => ({
                '--Switch-thumbSize': '20px',
                '--Switch-trackWidth': '53px',
                '--Switch-trackHeight': '27px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '25px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  '&:hover': {
                    '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  },
                },
              })}
            />
          )}
        </td>
        <td onMouseOver={() => setShowButtons(true)} onMouseOut={() => setShowButtons(false)}>
          {!showButtons && (
            <Button
              variant="plain"
              sx={{
                display: 'block',
                m: 0,
                borderBottom: '1px solid #947EFE',
                color: '#947EFE',
                borderRadius: 0,
                p: 0,
              }}
            >
              {t('cardChangeBtn')}
            </Button>
          )}

          {showButtons && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setEditOpen(!editOpen)}
              >
                {t('cardEditBtn')}
              </Button>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  ml: 1,
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setDeleteOpen(!deleteOpen)}
              >
                {t('cardDeleteBtn')}
              </Button>
            </Box>
          )}
        </td>
      </tr>
      <EditCardModal
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        card={card}
        handleEditCard={handleEditCard}
        banks={banks}
        tagList={tagList}
      />
      <DeleteCardModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDeleteCard={handleDeleteCard}
        textBtn={t('card_modal_deleteBtn')}
        title={t('card_modal_deleteHeader')}
      />
    </>
  );
};
export default CardItem;

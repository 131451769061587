import { Button, Typography, } from '@mui/joy';

import React, { Dispatch, SetStateAction, useState } from 'react';
import { formatDateTime } from '../../utils/dateFormater';

import { getAllTrans, updateTrans } from '../../services/operators';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTransactions, getOperatorById } from '../../redux/auth/operations';
import { getGlobalUser } from '../../redux/auth/selectors';

import { Transactions } from '../../utils/types';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { initialFilterState } from '../../pages/History/History';
import ModalConfirm from '../../pages/Active/ModalConfirm';

const ActiveTransItem = ({
  notification,
}: {
  notification: Transactions;
  selected: readonly number[];

  setSelected: Dispatch<SetStateAction<readonly number[]>>;
}) => {
  const { t } = useTranslation();
  const [acceptTrans, setAcceptTrans] = useState(false);
  const { user } = useSelector(getGlobalUser);
  const dispatch = useDispatch<AppDispatch>();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [amount, setAmount] = useState(notification?.init_amount_uah);

  const firstName = notification.first_name ?? '';
  const lastName = notification.last_name ?? '';

  let fullName = firstName + ' ' + lastName;
  if (notification.customer_id && notification.customer_id.includes(':')) {
    const result = notification.customer_id.split(':');
    fullName += `\n(${result[0]})`;
  }

  const handleAcceptTrans = async () => {
    try {
      await updateTrans(notification.id, Number(amount));
      const fetch = async () => {
        const data = await getAllTrans(user.id as number, null, null, {
          ...initialFilterState,
          searchStatus: 'PROCESSING',
        });

        dispatch(getActiveTransactions(data.transactions));
      };
      fetch();
      dispatch(getOperatorById(user.id));
      setAcceptTrans(true);
    } catch {
      toast.error(t('toast_AcceptTrans'));
    }
  };

  const toggleOpenModal = () => {
    setOpenModalConfirm(!openModalConfirm);
  };
  const handleChange = (value: string) => {
    setAmount(+value);
  };

  const onSubmit = () => {
    handleAcceptTrans();
    toggleOpenModal();
  };

  const fullNameA = fullName ?? '';
  const nameVal = fullNameA + '\n' + (notification.utr ?? '');

  return (
    <>
      <tr>

        <td>
          <Typography level="body-md" sx={{ color: '#F1F1F1',overflowWrap:'anywhere' }}>
            {(notification.operator_bank_title && notification.operator_bank_title) || t('not_found')}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ color: '#CED4DA', overflowWrap: 'anywhere' }}>{notification.pocket_address}</Typography>
          <Typography level="body-xs" sx={{ color: '#F1F1F1' }}>
            {(notification.label && notification.label)}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ color: '#F1F1F1', fontWeight: '14px' }}>{formatDateTime(notification.createdAt)}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ fontWeight: 500, color: '#F1F1F1', }}>
            {Number(notification.init_amount_uah).toFixed(2)} {localStorage.getItem('base_currency')}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ fontWeight: 500, color: '#F1F1F1' }}>{nameVal}</Typography>
        </td>
        <td>
          <Button
            variant="plain"
            onClick={toggleOpenModal}
            disabled={(acceptTrans && true) || false}
            sx={{
              display: 'block', color: '#947EFE', fontSize: '16px', fontWeight: 500, borderBottom: '1px solid #947EFE',
              p: 0,
              borderRadius: 0,
              m: 0,
            }}
          >
            {t('active_table_confirm')}
          </Button>
        </td>
      </tr>
      <ModalConfirm openModal={openModalConfirm}
        toggleOpenModal={toggleOpenModal}
        onSubmit={onSubmit}
        amount={amount.toString()}
        is_amount_changeable={notification.is_amount_changeable}
        handleChange={handleChange}
      />
    </>
  );
};
export default ActiveTransItem;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  addNewCard,
  // getAllBanks,
  getBanks,
  getAllCards,
  getAllTags,
  updateCardAll,
} from '../services/operators';
import { AllCards, Banks, Tags } from '../utils/types';

import { deleteCard, editCard } from '../services/operators';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../redux/auth/selectors';

export const useCardList = ({
  userId,
  searchQuery,
  searchBank,
  setSearchQuery,
  setSearchBank,
  setSearchTag,
  pageSize,
  searchTag,
}: {
  userId: number | null;
  searchQuery: string;
  searchBank: string;
  setSearchQuery: Dispatch<SetStateAction<string>> | null;
  setSearchBank: Dispatch<SetStateAction<string>> | null;
  setSearchTag: Dispatch<SetStateAction<string>> | null;
  searchTag: string;
  pageSize: number | null;
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<AllCards>([]);
  const [count, setCount] = useState<number>(0);
  const [banks, setBanks] = useState([]);
  const [tags, setTags] = useState<Tags[]>([]);
  const [accessibilityAll, setAccessibilityAll] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { access_token } = useSelector(getGlobalUser);
  const [update, setUpdate] = useState(false);
  const [archCards, setArchCards] = useState<AllCards>([]);
  const [countArchCards, setCountArchCards] = useState<number>(0);
  const [currentArchPage, setCurrentArchPage] = useState<number>(1);
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };
  const handleArchPageClick = ({ selected }: { selected: number }) => {
    setCurrentArchPage(selected + 1);
  };

  const handleRestSearch = () => {
    setSearchQuery && setSearchQuery('');
    setSearchBank && setSearchBank('');
    setSearchTag && setSearchTag('');
  };

  useEffect(() => {
    if (!userId || pageSize === null) {
      return;
    }
    const fetch = async () => {
      const allCards = await getAllCards(
        userId,
        searchQuery,
        searchBank,
        currentPage,
        pageSize,
        searchTag,
        false
      );
      setItems((allCards && allCards[0]) || []);
      setCount((allCards && allCards[1]) || 0);
    };
    fetch();
  }, [searchQuery, searchBank, currentPage, searchTag, update, pageSize]);
  useEffect(() => {
    if (!userId || pageSize === null) {
      return;
    }
    const fetch = async () => {
      const allCards = await getAllCards(
        userId,
        searchQuery,
        searchBank,
        currentArchPage,
        pageSize,
        searchTag,
        true
      );
      setArchCards((allCards && allCards[0]) || []);
      setCountArchCards((allCards && allCards[1]) || 0);
    };
    fetch();
  }, [ currentArchPage, update, pageSize]);

  useEffect(() => {
    const fetch = async () => {
      const allBanks = await getBanks(access_token);
      setBanks(allBanks?.filter((bank: Banks) => bank.title !== 'Other bank'));
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const allTags = await getAllTags();
      setTags(allTags);
    };
    fetch();
  }, []);

  const updateTags = async () => {
    const allTags = await getAllTags();
    setTags(allTags);
  };

  const expDateValidate = (year: string) => {
    if (Number(year) > 2035) return 'Expiry Date Year cannot be greater than 2035';
    return;
  };

  const handleChangeCheckbox = async (selected: readonly number[]) => {
    const cards = selected.map(itemId => ({ id: itemId, is_active: !accessibilityAll }));
    try {
      if (!userId) {
        return;
      }
      setAccessibilityAll(!accessibilityAll);
      const updatedCards = await updateCardAll(userId, cards);

      setItems(updatedCards[0]);
      handleRestSearch();
    } catch {
      toast.error(t('toast_updateCard'));
    }
  };

  const handleEditCard = async (
    cards: {
      id: number;
      bank_id: number;
      tag: number | null;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      send_id: string;
      daily_transaction_limit: number;
      allow_same_amount: boolean;
    }[]
  ) => {
    if (!userId) {
      return;
    }
    const updatedCard = await editCard(userId, cards);
    if (updatedCard) setItems(updatedCard[0]);
  };

  const handleAddCart = async ({
    cardNumber,
    bank,
    tag,
    accessibility,
    label,
    limit_by_the_day,
    limit_by_month,
    send_id,
    iban,
    email,
    iban_only,
    daily_transaction_limit,
    allow_same_amount
  }: {
    cardNumber: string | number | readonly string[];
    bank: number;
    tag: number | null;
    accessibility: boolean;
    label: string;
    limit_by_the_day: number;
    limit_by_month: number;
    send_id: string;
    iban: string;
    email: string;
    iban_only: boolean;
    allow_same_amount: boolean;
    daily_transaction_limit: number;
  }) => {
    try {
      const newCard = await addNewCard(
        cardNumber,
        bank,
        accessibility,
        label,
        limit_by_the_day,
        limit_by_month,
        tag,
        send_id,
        iban,
        email,
        iban_only,
        daily_transaction_limit,
        allow_same_amount
      );

      if (!newCard) {
        return;
      }
      setItems((prev: AllCards) => {
        return [newCard, ...prev];
      });
    } catch (err: unknown) {
      toast.error(t('toast_addCard'));
    }
  };

  const handleDeleteCard = async (id: number) => {
    try {
      await deleteCard(id);
      setUpdate(!update)
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCard = async ({
    id,
    accessibility,
  }: {
    id: number;
    accessibility: boolean;
  }) => {
    const card = { id: id, is_active: accessibility };
    userId && (await updateCardAll(userId, [card]));
  };

  return {
    cards: items,
    expDateValidate,
    handleAddCart,
    banks,
    count,
    tags,
    handleDeleteCard,
    handleUpdateCard,
    handleChangeCheckbox,
    handleRestSearch,
    accessibilityAll,
    handlePageClick,
    handleEditCard,
    updateTags,
    archCards,
    countArchCards,
    handleArchPageClick
  };
};

import { Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOperatorCourse } from '../../services/operators';
import { ICourse } from '../../utils/types';
import css from './Course.module.css';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { socket } from '../../socket';
import useWidth from '../../hooks/useWidth';

const Course = () => {
  const { t } = useTranslation();
  const [course, setCourse] = useState<ICourse | null>(null);
  const { user, access_token } = useSelector(getGlobalUser);
  const { isMobile } = useWidth();
  useEffect(() => {
    const fetch = async () => {
      localStorage.setItem('base_currency', user.base_currency);
      const data = await getOperatorCourse(access_token, user.base_currency);
      setCourse(data);
    };
    fetch();
  }, []);

  useEffect(() => {
    socket.on('course', async () => {
      localStorage.setItem('base_currency', user.base_currency);
      const data = await getOperatorCourse(access_token, user.base_currency);

      setCourse(data);
    });
  }, []);

  return (
    <>
      <Typography
        level={isMobile ? 'body-md' : 'body-md'}
        className={css.course}
        data-time={`(${course && new Date(course.updatedAt).toLocaleString()})`}
        sx={{ color: '#F1F1F1', fontWeight: 500, marginRight:isMobile ? 1: '40px' }}
      >
        {(course &&
          `${parseFloat(course.buy_cost).toFixed(2)} / ${(parseFloat(course.buy_cost)/(1-user.calculatedTax)).toFixed(2)}`) ||
          t('header_course_notfound')}
      </Typography>
    </>
  );
};

export default Course;

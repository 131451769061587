import { Box, Button, Divider, Drawer, Option, Select, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllTrans,
  getCustomerConversion,
  getTransactionById,
  updateTrans,
} from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import ModalConfirm from '../../pages/History/ModalConfirm';
import { initialFilterState } from '../../pages/History/History';
import { getActiveTransactions, getOperatorById } from '../../redux/auth/operations';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';

const HistoryItemModal = ({
  id,
  open,
  onClose,
  onKeyDown,
}: {
  id: number;
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [transaction, setTransaction] = useState<Transactions | null>(null);
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [conversionResult, setConversionResult] = useState<React.ReactNode | null>(null);
  const [isButtonHidden, setIsButtonHidden] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      const data = await getTransactionById(id);

      if (data) {
        setTransaction(data);
        setAmount(data.init_amount_uah);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleChangeStatus = async (_event: React.SyntheticEvent | null, newValue: string | null) => {
    if (transaction !== null && newValue !== null) {
      toggleOpenModal();
    }
  };

  const handleButtonClick = async () => {
    try {
      if (transaction?.customer_id && transaction?.currency) {
        const result = await getCustomerConversion(transaction?.customer_id, transaction?.currency);

        setConversionResult(
          <Box>
            <Typography level="body-md">
              {`${t('customer_total_paid')}: ${result.total_paid}`}
            </Typography>
            <Typography level="body-md">
              {`${t('customer_total_expired')}: ${result.total_expired}`}
            </Typography>
          </Box>
        );

        setIsButtonHidden(true);
      }
    } catch (error) {
      setConversionResult(
        <Typography level="body-md" sx={{ color: 'red' }}>
          {t('error_fetching_conversion')}
        </Typography>
      );
    }
  };

  const toggleOpenModal = () => {
    setOpenModalConfirm(!openModalConfirm);
  };
  const handleChange = (value: string) => {
    setAmount(+value);
  };

  const onSubmit = () => {
    handleAcceptTrans();
    toggleOpenModal();
  };

  const handleAcceptTrans = async () => {
    if (transaction) {
      try {
        await updateTrans(transaction.id, Number(amount));
        const fetch = async () => {
          const data = await getAllTrans(transaction.operator.user.id as number, null, null, {
            ...initialFilterState,
            searchStatus: 'PROCESSING',
          });

          dispatch(getActiveTransactions(data.transactions));
        };
        fetch();
        dispatch(getOperatorById(transaction.operator.user.id));
      } catch(error) {
        toast.error(t('toast_AcceptTrans'));
      }
    }
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Box role="presentation" onKeyDown={onKeyDown}>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} level="h4">
            {t('details_trans_header')}
          </Typography>
          {!transaction && (
            <Typography sx={{ mb: 3 }} level="body-md">
              {t('not_found')}
            </Typography>
          )}
          {transaction && (
            <>
              <Box>
                <Typography level="title-lg" sx={{ color:'#947EFE', mb: 2 }}>
                  {t('details_trans_name')}
                </Typography>
                <Typography level="body-md">{`ID: ${transaction.id}`}</Typography>
                <Box sx={{display:'flex'}}>
                  <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>{`ID (hash): ${transaction.hashed_id}`}</Typography>
                  <CopyToClipboard text={transaction.hashed_id} onCopy={onCopyText}>
                    <span style={{
                      padding: '4px',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      color: '#fff',
                      transition: 'background-color 0.3s ease',
}}>
                      {(isCopied && <LuCopyCheck size={17} />) || <LuCopy size={17} />}
                    </span>
                  </CopyToClipboard>
                </Box>
               
                <Typography level="body-md" sx={{overflowWrap:'anywhere'}}>{`Order id: ${transaction.order_id}`}</Typography>
                <Typography level="body-md" sx={{overflowWrap:'anywhere'}}>{`Customer id: ${transaction.customer_id}`}</Typography>
                <Typography level="body-md" sx={{overflowWrap:'anywhere'}}>{`P2P / IBAN: ${transaction.priority_bank === 29 ? 'IBAN' : 'P2P'}`}</Typography>
                <Typography level="body-md">
                  {`${t('history_table_startDate')}: ${formatDateTime(transaction.createdAt)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_endDate')}: ${formatDateTime(transaction.updatedAt)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_course')}: ${Number(transaction.currentCourse).toFixed(2)}`}
                </Typography>

                <Typography level="body-md">
                  {`${t('history_table_priceUah', { currency: (localStorage.getItem('base_currency')) || '', })}: ${Number(
                    transaction.confirmed_amount_uah
                  ).toFixed(2)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_initial_price', {
                    currency: (localStorage.getItem('base_currency')) || '',
                  })}: ${Number(
                    transaction.initial_amount_uah
                  ).toFixed(2)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUsdt')}: ${Number(transaction.operatorAmount).toFixed(2)}`}
                </Typography>
                { transaction.first_name && (
                  <Typography level="body-md">
                    {`${t('first_name')}: ${transaction.first_name}`}
                  </Typography>
                )}
                { transaction.last_name && (
                  <Typography level="body-md">
                    {`${t('last_name')}: ${transaction.last_name}`}
                  </Typography>
                )}
                { transaction.utr && (
                  <Typography level="body-md">
                    {`${t('utr')}: ${transaction.utr}`}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography level="body-md">{t('history_table_status') + ':'}</Typography>
                  {transaction.status === 'EXPIRED' || transaction.status === 'DISPUTE' ? (
                    <>
                      <Typography
                        level="body-md"
                        sx={{ color: getStatusTextColor(transaction.status) }}
                      >
                        {transaction.status}
                      </Typography>
                      <Select
                        placeholder={transaction.status}
                        variant="outlined"
                        slotProps={{
                          listbox: {
                            sx: {
                              '--List-padding': '0px',
                              '--ListItem-radius': '0px',
                            },
                          },
                        }}
                        onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                          handleChangeStatus(_event, newValue);
                        }}
                      >
                        <Option
                          value={'PAID'}
                          sx={{
                            p: 0,
                          }}
                        >
                          PAID
                        </Option>
                      </Select>
                      <Typography
                        level="body-md"
                        sx={{ color: getStatusTextColor(transaction.status) }}
                      >
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      level="body-md"
                      sx={{ color: getStatusTextColor(transaction.status) }}
                    >
                      {transaction.status}
                    </Typography>
                  )}
                </Box>
                {!isButtonHidden && (
                  <Button
                    variant="plain"
                    onClick={handleButtonClick}
                    sx={{
                      display: 'block',
                      color: '#947EFE',
                      fontSize: '16px',
                      fontWeight: 500,
                      border: '1px solid #947EFE',
                      mb: 1,
                    }}
                  >
                    {t('show_customer_conversion')}
                  </Button>
                )}
                {conversionResult && (
                  <>
                    {conversionResult}
                  </>
                )}
                <Divider />
                <Typography level="title-lg" sx={{ color: '#947EFE', mt: 2, mb: 2 }}>
                  {t('details_trans_card')}
                </Typography>
                <Typography level="body-md">
                  {`${t('transaction_card')}: ${transaction.card.card.pocket_address}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('details_trans_label')}: ${transaction.label}`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <Typography level="body-md">{t('details_trans_Tag') + ':'}</Typography>
                  <Typography
                    level="body-sm"
                    sx={{
                      bgcolor:
                        (transaction?.card?.card.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
                      width: 'fit-content',
                      p: '2px 10px',
                      borderRadius: '8px',
                    }}
                  >
                    {transaction?.card?.card?.tag?.title !== null
                      ? `#${transaction?.card?.card?.tag?.title}`
                      : t('not_found')}
                  </Typography>
                </Box>
                <Typography sx={{ mb: 2 }} level="body-md">
                  {`${t('transaction_bank')}: ${transaction.operator_bank_title}`}
                </Typography>
                <Divider />
                <Typography level="title-lg" sx={{ color: '#947EFE', mt: 2, mb: 2 }}>
                  {t('details_trans_operator')}
                </Typography>
                <Typography level="body-md">{`ID: ${transaction.operator?.user.id}`}</Typography>
                <Typography level="body-md">
                  {`${t('settings_checkbox_email')}: ${transaction.operator?.user.email}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('details_name')}: ${transaction.operator?.user.name}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('details_trans_tax')}: ${Number(transaction.operators_fee).toFixed(2)} %`}
                </Typography>
                <Typography sx={{ mb: 2 }} level="body-md">
                  {`${t('details_trans_profit')} ${Number(transaction.operator_profit).toFixed(2)}`}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {(transaction && amount) && (
          <ModalConfirm openModal={openModalConfirm}
                        toggleOpenModal={toggleOpenModal}
                        onSubmit={onSubmit}
                        amount={amount.toString()}
                        handleChange={handleChange}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default HistoryItemModal;

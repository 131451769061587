import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './locales/en/en.json';
import uaJson from './locales/uk-UA/uk-UA.json';
import ruJson from './locales/ru-RU/ru-RU.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  'en-US': {
    ...enJson,
  },
  'uk-UA': {
    ...uaJson,
  },
  'ru-RU': {
    ...ruJson,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en-US',
  });

export default i18n;

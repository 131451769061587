import { Box, Button, FormControl, Input, Modal, Option, Select, Sheet, Switch, switchClasses, Theme, Typography } from '@mui/joy';
import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { phonePattern } from '../../utils/patterns';
import { Banks, Cards, Tags } from '../../utils/types';
import Error from '../Error';
import { useState } from 'react';
import { getAllTags } from '../../services/operators';
import { selectClasses } from '@mui/joy/Select';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
interface IFormInput {
  bank: string;
  tag: string;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  send_id: string;
  iban: string;
  email: string;
  iban_only: boolean;
  daily_transaction_limit: number;
  allow_same_amount: boolean;
}

const EditCardModal = ({
  editOpen,
  setEditOpen,
  card,
  handleEditCard,
  banks,
}: {
  editOpen: boolean;
  setEditOpen: Dispatch<SetStateAction<boolean>>;
  card: Cards;
  banks: Banks[];
  tagList: Tags[];
  handleEditCard: (
    cards: {
      id: number;
      bank_id: number;
      tag: number | null;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      send_id: string;
      iban: string;
      email: string;
      iban_only: boolean;
      daily_transaction_limit: number;
      allow_same_amount: boolean;
    }[]
  ) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  const [tagList, setTagList] = useState<Tags[]>([]);
  const { user } = useSelector(getGlobalUser);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const onSubmit = ({
    bank,
    tag,
    limit_by_month,
    limit_by_the_day,
    label,
    accessibility,
    iban_only,
    send_id,
    iban,
    email,
    daily_transaction_limit,
    allow_same_amount,
  }: IFormInput) => {
    const cardForUpdate = {
      id: card.id,
      bank_id: Number(bank),
      tag: (tag && Number(tag)) || null,
      monthly_limit: Number(limit_by_month),
      dailyLimit: Number(limit_by_the_day),
      label: label,
      is_active: accessibility,
      iban_only: iban_only,
      send_id,
      iban,
      email,
      daily_transaction_limit: Number(daily_transaction_limit),
      allow_same_amount
    };
    handleEditCard([cardForUpdate]);
    setEditOpen(!editOpen);
    reset();
  };
  const getData = async () => {
    const allTags = await getAllTags();
    setTagList(allTags);
  }
  return (
    <Modal
      open={editOpen}
      onClose={() => {
        setEditOpen(!editOpen);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '40%',
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography level="h4" sx={{ textAlign: 'center', mb: 2 }}>
          {t('card_modal_edit')}
        </Typography>
        <Typography level="h4" sx={{ mb: 2 }}>
          {t('card_table_card')}: {card.pocket_address}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('card_modal_lebel')}
          </Typography>
          <Input
            {...register('label')}
            defaultValue={card.label}
            name="label"
            id="label"
            placeholder={t('card_modal_lebel')}
            sx={{
              mt: 1,
              '--Input-minHeight': '56px',
              '--Input-radius': '6px',
              borderBottom:
                watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
            }}
          />
          <Error errors={errors} name="label" />
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('cardModalID')}
          </Typography>
          <Input
            {...register('send_id')}
            defaultValue={card?.send_id}
            name="send_id"
            id="send_id"
            placeholder={t('cardModalID')}
            sx={{
              mt: 1,
              '--Input-minHeight': '56px',
              '--Input-radius': '6px',
              borderBottom:
                watch('send_id', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
            }}
          />
          <Error errors={errors} name="iban" />
          {user.base_currency === 'ARS' && (
            <>
            <Typography sx={{ mt: 2 }} level="body-md">
              {t('card_modal_email')}
            </Typography>
            <Input
              {...register('email')}
              defaultValue={card?.email}
              name="email"
              id="email"
              placeholder={t('card_modal_email')}
              sx={{
                mt: 1,
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('email', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="email" />
            </>
          )}
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('cardModalIban')}
          </Typography>
          <Input
            {...register('iban')}
            defaultValue={card?.iban}
            name="iban"
            id="iban"
            placeholder={t('cardModalIban')}
            sx={{
              mt: 1,
              '--Input-minHeight': '56px',
              '--Input-radius': '6px',
              borderBottom:
                watch('iban', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
            }}
          />
          <Error errors={errors} name="iban" />
            <Typography sx={{ mt: 2 }} level="body-md">
              {t('cardModalDailyLimit')}
            </Typography>
            <Input
              {...register('daily_transaction_limit')}
              name="daily_transaction_limit"
              id="daily_transaction_limit"
              defaultValue={card.daily_transaction_limit}
              type="number"
              slotProps={{
                input: {
                  min: 1,
                },
              }}
              placeholder={t('cardModalDailyLimit')}
              sx={{
                mt: 2,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="daily_transaction_limit" />

          <Box sx={{ display: isMobile ? 'block':'flex', gap: 2, mt: 2 }}>
            <FormControl sx={{ width: '100%' }}>
              <Typography level="body-md">
                {t('card_modal_monthLimit')}
              </Typography>
              <Input
                {...register('limit_by_month', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                })}
                defaultValue={card.month_limit}
                name="limit_by_month"
                id="limit_by_month"
                placeholder={t('card_modal_monthLimit')}
                sx={{
                  '--Input-minHeight': '56px',
                  '--Input-radius': '6px',
                  width: '100%',
                  borderBottom: errors.limit_by_month
                    ? '1px solid rgba(235, 87, 87, 1)'
                    : '1px solid rgba(24, 232, 6, 1)',
                }}
              />
              <Error errors={errors} name="limit_by_month" />
            </FormControl>
            <FormControl sx={{ width: '100%', mt:isMobile ? 2 : 0}}>
              <Typography level="body-md">
                {t('card_modal_dayLimit')}
              </Typography>
              <Input
                {...register('limit_by_the_day', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                })}
                defaultValue={card.daily_limit}
                name="limit_by_the_day"
                id="limit_by_the_day"
                placeholder={t('card_modal_dayLimit')}
                sx={{
                  '--Input-minHeight': '56px',
                  '--Input-radius': '6px',
                  width: '100%',
                  borderBottom:
                    parseFloat(watch('limit_by_the_day')?.toString()) >
                      parseFloat(watch('limit_by_month')?.toString())
                      ? '1px solid rgba(235, 87, 87, 1)'
                      : '1px solid rgba(24, 232, 6, 1)',
                }}
              />
              <Error errors={errors} name="limit_by_the_day" />
            </FormControl>
          </Box>
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('card_modal_bank')}
          </Typography>
          <Controller
            control={control}
            name="bank"
            defaultValue={card.bank.bank.id.toString()}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={t('card_modal_bank')}
                defaultValue={card.bank.bank.id.toString()}
                onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                  onChange(newValue)
                }
                value={value}
                indicator={
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                    />
                  </svg>
                }
                sx={{
                  [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                      transform: 'rotate(-180deg)',
                    },
                  },
                  mt: 1,
                  height: '56px',
                  '--Input-minHeight': '56px',
                  '--Input-radius': '6px',
                  borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : 'inherit',
                }}
              >
                {banks &&
                  banks.map(({ title, id }) => (
                    <Option key={id} value={id.toString()}>
                      {title}
                    </Option>
                  ))}
              </Select>
            )}
          />

          <Error errors={errors} name="bank" />
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('card_modal_tag')}
          </Typography>
          <Controller
            control={control}
            name="tag"
            defaultValue={card.tag?.id.toString()}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={t('card_modal_tag')}
                defaultValue={card.tag?.id.toString()}
                onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                  onChange(newValue)
                }
                onClick={getData}
                value={value}
                indicator={
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                    />
                  </svg>
                }
                sx={{
                  [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                      transform: 'rotate(-180deg)',
                    },
                  },
                  mt: 1,
                  height: '56px',
                  '--Input-minHeight': '56px',
                  '--Input-radius': '6px',
                  borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : 'inherit',
                }}
              >
                {tagList &&
                  tagList.map(({ title, id }) => (
                    <Option key={id} value={id.toString()}>
                      {title}
                    </Option>
                  ))}
              </Select>
            )}
          />

          <Error errors={errors} name="tag" />

          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', mt: '20px', mb: '20px' }}>
            <Typography level="body-lg">{t('card_modal_accessibility')}</Typography>
            <Controller
              control={control}
              name="accessibility"
              defaultValue={card.is_active}
              render={({ field: { onChange, value } }) => (
                <Switch
                  size="md"
                  variant="solid"
                  defaultValue={card.is_active}
                  value={value}
                  sx={(theme: Theme) => ({
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '51px',
                    '--Switch-trackHeight': '31px',
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                    [`& .${switchClasses.thumb}`]: {
                      transition: 'width 0.2s, left 0.2s',
                    },
                    '&:hover': {
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                    },
                    '&:active': {
                      '--Switch-thumbWidth': '32px',
                    },
                    [`&.${switchClasses.checked}`]: {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      '&:hover': {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      },
                    },
                  })}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange={(
                    event: { target: { checked: boolean } },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    _newValue: string
                  ) => {
                    onChange(event.target.checked);
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', mt: '20px', mb: '20px' }}>
            <Typography level="body-lg">{t('card_modal_iban_only')}</Typography>
            <Controller
              control={control}
              name="iban_only"
              defaultValue={card.iban_only}
              render={({ field: { onChange, value } }) => (
                <Switch
                  size="md"
                  variant="solid"
                  checked={value}
                  sx={(theme: Theme) => ({
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '51px',
                    '--Switch-trackHeight': '31px',
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                    [`& .${switchClasses.thumb}`]: {
                      transition: 'width 0.2s, left 0.2s',
                    },
                    '&:hover': {
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                    },
                    '&:active': {
                      '--Switch-thumbWidth': '32px',
                    },
                    [`&.${switchClasses.checked}`]: {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      '&:hover': {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      },
                    },
                  })}
                  onChange={(event) => onChange(event.target.checked)}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', mt: '20px', mb: '20px' }}>
            <Typography level="body-lg">{t('card_allow_same_amount')}</Typography>
            <Controller
              control={control}
              name="allow_same_amount"
              defaultValue={card.allow_same_amount}
              render={({ field: { onChange, value } }) => (
                <Switch
                  size="md"
                  variant="solid"
                  checked={value}
                  sx={(theme: Theme) => ({
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '51px',
                    '--Switch-trackHeight': '31px',
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                    [`& .${switchClasses.thumb}`]: {
                      transition: 'width 0.2s, left 0.2s',
                    },
                    '&:hover': {
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                    },
                    '&:active': {
                      '--Switch-thumbWidth': '32px',
                    },
                    [`&.${switchClasses.checked}`]: {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      '&:hover': {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      },
                    },
                  })}
                  onChange={(event) => onChange(event.target.checked)}
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="outlined"
            className="btnGradient"
            sx={{
              width: '100%',
              p: '17px 0',
              fontSize: '14px',
              color: '#f1f1f1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',

              },
            }}
          >
            {t('settings_save_btn').toUpperCase()}
          </Button>
        </form>
      </Sheet>
    </Modal>
  );
};

export default EditCardModal;
